<template>
<div>
    <div class="vx-row">
        <div class="vx-col w-full  mt-8">
            <vx-card class="call-getting">
                <div class="card-title flex-wrap" id="v-step-0">
                    <h2 class="text-center">Email Tracking</h2>
                    <h3 class="text-center">See what happens after you click "SEND"...</h3>
                </div>
                <div class="emailtrackbox">
                    <div class="vx-row justify-center">
                        <div class="vx-col xl:w-1/4 md:w-1/3 mb-4 md:mb-0 w-full">
                            <a :href="gmailExtensionLink" target="_blank" class="inneremail-box gmail-border">
                                <h3 class="text-gmail">Gmail</h3>
                                <a :href="gmailExtensionLink" target="_blank">Click the icon below to download and install our Google Chrome Extension from the Chrome Web Store. The install takes just seconds.</a>
                                <img src="../../assets/images/gmail.svg" />
                            </a>
                        </div>
                        <div class="vx-col xl:w-1/4 md:w-1/3 mb-4 md:mb-0 w-full" v-if="!this.$store.state.isReseller">
                            <a href="https://appsource.microsoft.com/en-us/product/office/WA200003808" target="_blank" class="inneremail-box outlook-border">
                                <h3 class="text-outlook">Outlook </h3>
                                <a href="https://appsource.microsoft.com/en-us/product/office/WA200003808" target="_blank">Click the icon below to download our Outlook add-in. This install takes just a minute.</a>
                                <img src="../../assets/images/outlook.svg" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="call-history email-tracking-history">
                    <div class="call-btn"><a href="#" title="Download Extension" class="bg-primary">Download Extension</a></div>
                    <ul class="email-call-history">
                        <li>
                            <div class="history-title bg-first-gradient">
                                <feather-icon icon="TagIcon" class="border-first" />
                                <h3>Authenticate Chrome Extension to {{loggedInCompanyName}}</h3>
                            </div>
                            <div class="history-title bg-first-gradient history-info">
                                <p class="text-white">Enter your {{loggedInCompanyName}} credentials to authenticate</p>
                                <img src="../../assets/images/authenticate.jpg" v-if="!this.$store.state.isReseller" />
                                <img src="../../assets/images/authenticate1.png" v-if="this.$store.state.isReseller" />
                            </div>
                        </li>
                        <li>
                            <div class="history-title bg-second-gradient">
                                <feather-icon icon="CheckIcon" class="border-second" />
                                <h3>New Options Added to your Gmail Compose Window</h3>
                            </div>
                            <div class="history-title bg-second-gradient history-info">
                                <p class="text-white" v-if="!this.$store.state.isReseller">You can now use the "Tag" checkbox to track this email. Click the {{loggedInCompanyName}} Icon to use available email templates.</p>
                                <p class="text-white" v-if="this.$store.state.isReseller">You can now use the "Tag" checkbox to track this email. Click the Email Tracker Icon to use available email templates.</p>
                                <img src="../../assets/images/tagbutton.png" v-if="!this.$store.state.isReseller" />
                                <img src="../../assets/images/tagbutton1.png" v-if="this.$store.state.isReseller" />
                            </div>
                        </li>
                        <li>
                            <div class="history-title bg-third-gradient">
                                <feather-icon icon="AlertOctagonIcon" class="border-third" />
                                <h3>Receive Instant Desktop Notifications</h3>
                            </div>
                            <div class="history-title bg-third-gradient history-info">
                                <p class="text-white">You will now receive instant desktop notifications for both emails opened and website visitors.</p>
                                <img src="../../assets/images/notification.png" v-if="!this.$store.state.isReseller" />
                                <img src="../../assets/images/notification1.png" v-if="this.$store.state.isReseller" />
                            </div>
                        </li>
                        <li>
                            <div class="history-title bg-fourth-gradient">
                                <feather-icon icon="MapPinIcon" class="border-fourth" />
                                <h3>Receive Detailed Email Notifications</h3>
                            </div>
                            <div class="history-title bg-fourth-gradient history-info">
                                <p class="text-white">You will now receive an email notification detailing information on your tagged email.</p>
                                <img src="../../assets/images/emailnotification.png" />
                            </div>
                        </li>
                    </ul>
                    <div class="call-btn more-sales"><a href="#" title="Make More Sales" class="bg-primary">Make More Sales</a></div>
                </div>
                <div class="track-easy">
                    <div class="vx-row mb-12 justify-center">
                        <div class="vx-col w-full">
                            <h4>Email Tracking Made Easy</h4>
                            <p>Tagged emails look the same as your current emails and arrive from your address.</p>
                        </div>
                    </div>
                    <div class="vx-row justify-center">
                        <div class="vx-col xl:w-1/4 lg:w-1/3 md:w-1/3 mb-4 md:mb-0 w-full">
                            <div class="track-email-info">
                                <feather-icon icon="MailIcon" />
                                <p>Track your email with a single click and all your links will be tagged automatically for {{loggedInCompanyName}} integration.</p>
                            </div>
                        </div>
                        <div class="vx-col xl:w-1/4 lg:w-1/3 md:w-1/3 mb-4 md:mb-0 w-full">
                            <div class="track-email-info">
                                <feather-icon icon="LockIcon" />
                                <p>Once your email is tagged, it will be sent from your own server and arrive from your email address.</p>
                            </div>
                        </div>
                        <div class="vx-col xl:w-1/4 lg:w-1/3 md:w-1/3 mb-4 md:mb-0 w-full">
                            <div class="track-email-info">
                                <feather-icon icon="BarChart2Icon" />
                                <p>Reporting to help you understand who opened your email, where your email was opened geographically and when your email was opened.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="emailtrackbox mt-4 mb-0">
                    <div class="vx-row justify-center">
                        <div class="vx-col w-full">
                            <div class="card-title justify-center mt-6 flex-wrap">
                                <h2 class="text-center">Get Started</h2>
                            </div>
                        </div>
                        <div class="vx-col xl:w-1/4 md:w-1/3 mb-4 md:mb-0 w-full">
                            <a :href="gmailExtensionLink" target="_blank" class="inneremail-box gmail-border">
                                <h3 class="text-gmail">Gmail</h3>
                                <a :href="gmailExtensionLink" target="_blank">Click the icon below to download and install our Google Chrome Extension from the Chrome Web Store. The install takes just seconds.</a>
                                <img src="../../assets/images/gmail.svg" />
                            </a>
                        </div>
                        <div class="vx-col xl:w-1/4 md:w-1/3 mb-4 md:mb-0 w-full" v-if="!this.$store.state.isReseller">
                            <a href="https://appsource.microsoft.com/en-us/product/office/WA200003808" target="_blank" class="inneremail-box outlook-border">
                                <h3 class="text-outlook">Outlook </h3>
                                <a href="https://appsource.microsoft.com/en-us/product/office/WA200003808" target="_blank">Click the icon below to download our Outlook add-in. This install takes just a minute.</a>
                                <img src="../../assets/images/outlook.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
//import Cookie from "js-cookie";

export default {
    components: {
        VxCard
    },
    data() {
        return {
            gmailExtensionLink: null,
            loggedInCompanyName: null,
            myOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Next Page'
                }
            },
        }
    },
    created() {
        this.getLoggedInCompanyName()
        this.gmailExtensionLink = !this.$store.state.isReseller ? 'https://chrome.google.com/webstore/detail/visual-visitor/fjjbojebgpnkbckhngbekcigpfcamkad' : 'https://chrome.google.com/webstore/detail/email-tracker/bdipodfilgckdlnfmddcbbfjfjifgdnd'
    },
    mounted() {
        // if(Cookie.get("VVGuidedTour") === undefined){
        //     this.$tours['VVGuidedTour'].start()
        // }
    },
    methods: {
        getLoggedInCompanyName() {
            this.$vs.loading();
            this.axios.get("/ws/Organization/GetOrganization").then(response => {
                let data = response.data;
                this.loggedInCompanyName = data.Org_Name;
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        }
    }

};
</script>
